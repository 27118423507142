import { useGLTF } from '@react-three/drei';
import React, { useEffect, useState } from 'react'
import sofaObj from './assets/sofa/SofaOptimized-transformed.glb'
import normalMapMaterial from './assets/sofa/Textures/normal.jpg'
import roughMapMaterial from './assets/sofa/Textures/roughness.jpg'
import aoMapMaterial from './assets/sofa/Textures/roughness.jpg'
import greenColorMaterial from './assets/sofa/Textures/yellowcolor.jpg'
import blueColorMaterial from './assets/sofa/Textures/bluecolor.jpg'
import grayColorMaterial from './assets/sofa/Textures/graycolor.jpg'
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';

const Sofa = ({ x = 0, y = -1, z= 0, position, sofaTexture}) => {

    let items ={

         0: greenColorMaterial,
       
           1: blueColorMaterial,
        
        
        
          2: grayColorMaterial,
        }
    
    const [colorMat, setColorMat] = useState(greenColorMaterial)

    const [newposition, setNewPosition] = useState([0, -1, -2])

   
    let {scene} = useGLTF(sofaObj);

    const [colorMap, normalMap, roughnessMap, aoMap] = useLoader(TextureLoader, [
        colorMat,
       normalMapMaterial,
       roughMapMaterial,
       aoMapMaterial
      ]);
    
      useEffect(() => {
        scene.children[0].material.map = colorMap
        
    }, [colorMat])

    useEffect(() => {
        setColorMat(items[sofaTexture])
    }, [sofaTexture])
    useEffect(() => {
      
        scene.children[0].material.map = colorMap
      scene.children[0].material.normalMap = normalMap
      scene.children[0].material.roughnessMap = roughnessMap
      scene.children[0].material.aoMap = aoMap
      scene.children[0].material.roughness = 1
      scene.children[0].material.lightMapIntensity = 0.6

       scene.rotation.set(7.85, -8, 0)
       
       scene.scale.set(10, 10, 10) 

       scene.castShadow = true
       scene.receiveShadow = true
       scene.children[0].castShadow = true
    //    scene.children[0].receiveShadow = true
    console.log(scene)
    }, [])
    useEffect(() => {
        scene.position.set(newposition[0],newposition[1],newposition[2])
        console.log(position)
        setNewPosition([
            position[0],
            position[1] - 1,
            - 2, 
        ])
    }, [position])


    return <primitive object={scene} />
}

export default Sofa