import { useRef, useState } from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import { SoftShadows, Float, CameraControls, Sky, PerformanceMonitor } from "@react-three/drei"
import { useControls } from "leva"
import { Perf } from "r3f-perf"
import { easing } from "maath"
import { Model as Room } from "./Room"

import replyIcon from './assets/icons/reply.svg'
import heartIcon from './assets/icons/heart.svg'
import downloadIcon from './assets/icons/download.svg'
import html2canvas from "html2canvas"

function Light() {
  const ref = useRef()
  useFrame((state, delta) => {
    easing.dampE(ref.current.rotation, [(state.pointer.y * Math.PI) / 50, (state.pointer.x * Math.PI) / 20, 0], 0.2, delta)
  })
  return (
    <group ref={ref}>
      <directionalLight position={[5, 5, -8]} castShadow intensity={5} shadow-mapSize={2048} shadow-bias={-0.001}>
        <orthographicCamera attach="shadow-camera" args={[-8.5, 8.5, 8.5, -8.5, 0.1, 20]} />
      </directionalLight>
    </group>
  )
}

export default function App({changeWall, changeSofa, furniturePosition, openSideBar, setOpenSideBar, setRoom, sofaTexture}) {
  const [bad, set] = useState(false)
//   const { impl, debug, enabled, samples, ...config } = useControls({
//     debug: true,
//     enabled: true,
//     size: { value: 35, min: 0, max: 100, step: 0.1 },
//     focus: { value: 0.5, min: 0, max: 2, step: 0.1 },
//     samples: { value: 16, min: 1, max: 40, step: 1 }
//   })
  return (
    <div className={`${openSideBar?.length !==0 ? 'w-4/5' : 'w-full'} relative`}>
        <div className="z-[16588377] absolute  top-10 right-10 flex item-center" >
            <div className="p-3 min-w-max m-1 cursor-pointer rounded-xl shadow-lg bg-white flex items-center" onClick={() => setRoom(false)}>
              <div className="w-8">
                <img src={replyIcon} />
              </div>
              <p>بازگشت به انتخاب محیط</p>

            </div>
            <div className="p-3 min-w-max m-1 cursor-pointer rounded-xl shadow-lg bg-white flex items-center" >
              <div className="">
                <img src={heartIcon} />
              </div>
              

            </div>
            <div id="asdasd" className="p-3 min-w-max m-1 cursor-pointer rounded-xl shadow-lg bg-white flex items-center" onClick={() => {
              let div = document.getElementById('canvi')
              html2canvas(div).then(async (canvas) =>  {
                var a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = 'Room_configuration_screenshot.jpg';
        a.click();
              })
            
            }}>
              <div className="">
                <img src={downloadIcon} />
              </div>
              

            </div>
        </div>
        
        {/* <div className={`z-[16588377] absolute w-8 h-8 p-1 rounded-full border border-white  top-1/2 right-1/2  flex item-center justify-center`}>
            <div className="w-full h-full bg-white rounded-full" onClick={() => {}}>

            </div>
        </div> */}
       
        
 <Canvas id="canvi" gl={{preserveDrawingBuffer: true}} shadows camera={{ position: [5, 2, 10], fov: 50 }}>
      {/* {debug && <Perf position="top-left" />} */}
      {/* <PerformanceMonitor onDecline={() => set(true)} /> */}
  {/* <SoftShadows samples={bad ? Math.min(6, 16) : 16} /> */}
      <CameraControls makeDefault />
      <color attach="background" args={["#d0d0d0"]} />
      <fog attach="fog" args={["#d0d0d0", 8, 35]} />
      <ambientLight intensity={0.4} />
      <Light />
      <Room sofaTexture={sofaTexture} setOpenSideBar={(e) => setOpenSideBar(e)}  furniturePosition={furniturePosition} changeSofa={changeSofa} changeWall={changeWall}/>
      <Sphere />
      <Sphere position={[2, 4, -8]} scale={0.9} />
      <Sphere position={[-2, 2, -8]} scale={0.8} />
      <Sky inclination={0.52} scale={20} />
    </Canvas>
    
    </div>
   
  )
}

function Sphere({ color = "hotpink", floatIntensity = 15, position = [0, 5, -8], scale = 1 }) {
  return (
    <Float floatIntensity={floatIntensity}>
      <mesh castShadow position={position} scale={scale}>
        <sphereGeometry />
        <meshBasicMaterial color={color} roughness={1} />
      </mesh>
    </Float>
  )
}
