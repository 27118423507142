/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 room.glb --transform
Author: Anex (https://sketchfab.com/anex)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/room-6417cbc1870a4a1691cca06912ae0369
Title: Room
*/

import { Circle, useGLTF } from "@react-three/drei"
import { useLoader } from "@react-three/fiber"
import { useEffect } from "react"
import { TextureLoader } from "three"
import * as THREE from 'three'
import Sofa from "./Sofa"

export function Model({changeWall, changeSofa, furniturePosition, setOpenSideBar, sofaTexture}) {
  const { nodes, materials } = useGLTF("/room-transformed.glb")
//   let changeWall = props.changeWall
  const txture  = useLoader(TextureLoader, '/wall1.jpg');
  useEffect(() => {
    [txture].forEach((t) => {
      t.wrapS = THREE.RepeatWrapping;
      t.wrapT = THREE.RepeatWrapping;
      t.repeat.set(5, 5);
    });
    txture.encoding = THREE.LinearEncoding;


  }, [txture]);
  console.log(changeWall)

 


    const material = new THREE.MeshStandardMaterial({
        map: txture
    })
  return (
    <group scale={0.5} position={[0, -1, 0]}  dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.Object_2.geometry} material={materials.Material} />
        <mesh castShadow receiveShadow geometry={nodes.Object_3.geometry} material={materials["Material.002"]} />
        <mesh castShadow receiveShadow geometry={nodes.Object_4.geometry}  onClick={() => alert('clicked')} material={changeWall ? material : materials['Material.003']} />
       {
        !changeSofa && <mesh 
        onPointerOut={() => document.getElementsByTagName('body')[0].style.cursor = 'auto'}
        onPointerOver={() => document.getElementsByTagName('body')[0].style.cursor = 'pointer'} 
        onClick={() => setOpenSideBar('furniture')}>
          {/* <Circle args={[0.5, 32]} rotation={[2,0,2]} position={[0,-2,2]}/> */}
         <mesh castShadow receiveShadow geometry={nodes.Object_6.geometry}position={furniturePosition} material={materials.krzeslo_1} />
        <mesh castShadow receiveShadow geometry={nodes.Object_7.geometry}position={furniturePosition} material={materials.krzeslo_okno} />
        <mesh castShadow receiveShadow geometry={nodes.Object_8.geometry} position={furniturePosition}material={materials.krzeslo_prawe} />
        <mesh castShadow receiveShadow geometry={nodes.Object_9.geometry}position={furniturePosition} material={materials.krzeslo_srodek} />
       
        <mesh castShadow receiveShadow geometry={nodes.Object_17.geometry} position={furniturePosition} material={materials.mata} /> 
        <mesh castShadow receiveShadow geometry={nodes.Object_16.geometry} position={furniturePosition} material={materials["Material.006"]} />
        <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} position={furniturePosition} material={materials["Material.004"]} />
        <mesh castShadow receiveShadow geometry={nodes.Object_12.geometry} position={furniturePosition} material={materials["stolik.001"]} />

        </mesh>
       }
       {changeSofa && <group onClick={() => setOpenSideBar('furniture')}><Sofa sofaTexture={sofaTexture} position={furniturePosition}/> </group>}
   
        <mesh castShadow receiveShadow geometry={nodes.Object_10.geometry} material={materials.podloga} />
        <mesh castShadow receiveShadow geometry={nodes.Object_11.geometry} material={materials.sciana_okno} />
     
        <mesh geometry={nodes.Object_13.geometry}>
          <meshStandardMaterial transparent opacity={0.5} />
        </mesh>
        <mesh castShadow receiveShadow geometry={nodes.Object_14.geometry} material={materials["Material.002"]} />
        <mesh castShadow receiveShadow geometry={nodes.Object_15.geometry} material={materials["Material.005"]} />

        <mesh castShadow receiveShadow geometry={nodes.Object_18.geometry} material={materials.stolik} />
      </group>
    </group>
  )
}

useGLTF.preload("/room-transformed.glb")
