import React, { useRef, useState } from 'react'
import All from './All'
import roomImage from './assets/images/room1.png'
import logo from './assets/images/Frame.svg'

const Router = () => {
    const [room, setRoom] = useState(false)
    const allRef = useRef()
  return (
    room ? 
    <div
     ref={allRef}><All allRef={allRef} setRoom={setRoom} /></div>
    :
    <div
    
    className='w-full h-screen flex items-center justify-center' style={{backgroundImage: `url(${roomImage})`, backgroundSize:'100%'}}>
        <div className='fixed w-full h-full bg-black/80 z-20 top-0 right-0 backdrop-blur-sm' > </div>
        <div className='flex flex-col relative z-40 justify-between h-4/5 items-center'>
            <div>
                <img src={logo} />
            </div>
            <div className='flex flex-col items-center min-w-max'>
                <h1 className='text-white font-bold text-2xl'>محیط مورد نظر خود را انتخاب کنید:</h1>
                <div onClick={() => setRoom(true)} className=' w-40 h-40 cursor-pointer hover:scale-125  bg-white mt-6 rounded-2xl overflow-hidden'>
                        <img src={roomImage} className='object-cover w-full h-full' />
                </div>
            </div> 
            <div></div> 
        </div>
    </div>
  )
}

export default Router