import React, { useEffect, useRef, useState, createRef } from 'react'
import App from './App'
import Controls from './Controls'

const All = ({
    setRoom,
    
}) => {
    let thsRef = createRef(null)
    const [openSidebar, setOpenSideBar] = useState('')
    const [changeWall, setChangeWall] = useState(false)
    const [changeSofa, setChangeSofa] = useState(false)
    const [changePosition, setChangePosition] = useState(false)
    const [sofaTexture, setSofaTexture] = useState(0)
    const [position, setPosition] = useState([0,0,0])
    const [keys, setKeys] = useState({
        w: false,
        a: false,
        d: false,
        s: false
    })
    const positionControlValue = 0.5
    const onkeyDownControl = e => {
     console.log(e.key)
       if(changePosition){
        switch(e.key){
            
            case 'ArrowLeft':
                setKeys({...keys, a: true})
                
         
                setPosition([
                    position[0] - positionControlValue,
                    ...position?.filter((item, idx) => idx > 0)
                ])
                return null
                case 'ArrowRight':
                    setKeys({...keys, d: true})
                   
                    setPosition([
                        position[0] + positionControlValue,
                        ...position?.filter((item, idx) => idx > 0)
                    ])
                    return null  
                    case 'ArrowDown':
                    setKeys({...keys, s: true})
                   
                    setPosition([
                        position[0],
                        position[1] - positionControlValue,
                        position[2],
                    ])
                    return null   
                    case 'ArrowUp':
                        setKeys({...keys, w: true})
                       
                        setPosition([
                            position[0],
                            position[1] + positionControlValue,
                            position[2],
                        ])
                        return null   
        }
       }
    }


 const onkeyUpControl = e => {
    if(changePosition){
        switch(e.key){
  
            case 'ArrowUp':
                setKeys({...keys, w: false})
                
          
               
                return null
                case 'ArrowDown':
                    setKeys({...keys, s: false})
                   
                    
                    return null    
                    case 'ArrowLeft':
                        setKeys({...keys, a: false})
                       
                        
                        return null    
                        case 'ArrowRight':
                            setKeys({...keys, d: false})
                           
                            
                            return null    
                    
          }
    }
}

useEffect(() => {
console.log(changePosition)


}, [changePosition])
  return (
    <div className="section" ref={thsRef}
    
    >
    
    <App sofaTexture={sofaTexture} openSideBar={openSidebar} setOpenSideBar={setOpenSideBar} setRoom={setRoom} furniturePosition={position} changeWall={changeWall} changeSofa={changeSofa} />
   {
    openSidebar?.length !==0  &&  <Controls 
    onkeyDownControl={onkeyDownControl}
    onkeyUpControl={onkeyUpControl}
    sofaTexture={sofaTexture}
    setSofaTexture={setSofaTexture}
    setOpenSideBar={setOpenSideBar}
    keys={keys}
    thsRef={thsRef}
    position={position}
    setPosition={setPosition}
    setKeys={setKeys}
    positionControlValue={positionControlValue}
    changePosition={changePosition}
    setChangePosition={setChangePosition}
    setChangeWall={setChangeWall} changeSofa={changeSofa} setChangeSofa={setChangeSofa}  changeWall={changeWall}/>
   }
    {/* <Leva collapsed /> */}
  </div>
  )
}

export default All