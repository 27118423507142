import React, { useEffect, useRef, useState } from 'react'
import logo from './assets/images/Frame.svg'
import closeIcon from './assets/icons/close.svg'
import furnitureImage from './assets/images/furniture.png'
import sofaImage from './assets/images/sofa.png'
import greenColorMaterial from './assets/sofa/Textures/yellowcolor.jpg'
import blueColorMaterial from './assets/sofa/Textures/bluecolor.jpg'
import grayColorMaterial from './assets/sofa/Textures/graycolor.jpg'
import arrowIcon from './assets/icons/arrow.svg'

const Controls = ({setChangeWall, changeWall, changeSofa, setChangeSofa, 
    changePosition,
    setKeys,
    setChangePosition,
    position, setPosition,
    thsRef,
    positionControlValue,
    keys,
    setOpenSideBar,
    sofaTexture,
    setSofaTexture,
    onkeyUpControl,
    onkeyDownControl
}) => {
    let hiddnInptRef = useRef()
  const [choosedSection, setChoosedSection] = useState(0)

    const items = [
        {
            name: 'محصول',
            id: 0,
        },
        {
            name: 'جنس و طرح',
            id: 1,
        },
        {
            name: 'جایگاه',
            id: 2,
        },
    ]
    const objects = [
        {
            image: furnitureImage,
            id: false
        },
        {
            image: sofaImage,
            id: true
        }
    ]
    

    let sofaItems =[
        {
            image: greenColorMaterial,
            id: 0
        },
        {
            image: blueColorMaterial,
            id: 1
        },
        {
            image: grayColorMaterial,
            id: 2
        },

       
    ]

    useEffect(() => {
        if(choosedSection == 2){
            setChangePosition(true)
            hiddnInptRef.current.focus()
        }else{
            setChangePosition(false)
        }
        
    }, [choosedSection])
  return (
    <div
        onClick={() => {
            if(choosedSection == 2){
                hiddnInptRef.current.focus()
            }
        }}
    

    className='flex-1 h-screen bg-black15 p-8 flex flex-col items-center w-2/8'>
        <div className='w-full flex items-center justify-between mb-8'>
            <div className='w-10 '>
                <img src={logo} />
            </div>
            <div className='cursor-pointer' onClick={() => setOpenSideBar('')}>
                <img src={closeIcon} />
            </div>
        </div>

        <div className='flex items-center w-full mb-8'>
            {
                items.map(item => (
                    <div
                    onClick={() => setChoosedSection(item.id)}
                    className={`w-full text-center cursor-pointer text-xs p-3 border-b-2  ${choosedSection == item.id ? 'border-blue600 text-blue600' : 'border-gray97 text-gray97'}`}>
                        <p>{item.name}</p>
                    </div>
                ))
            }
        </div>

        {
            choosedSection == 0 &&
            <div className={`w-full grid grid-cols-3 gap-6`}>
                {
                    objects.map(item => (
                        <div className={`cursor-pointer bg-gray-200 rounded-xl w-full justify-stretch ${changeSofa == item.id ? 'border-4 border-blue900 outline outline-blue600' : ' border-4 border-transparent outline outline-transparent'}`} onClick={() => setChangeSofa(item.id)}>
                            <img src={item.image}  className='object-contain'/>
                        </div>
                    ))
                }
            </div>
        }

        {
            (choosedSection == 1 && changeSofa == true) && 
            <div className={`w-full grid grid-cols-5 gap-3`}>
                {
                    sofaItems?.map((item) =>
                    <div className={`cursor-pointer rounded-full p-1 w-full justify-stretch ${sofaTexture == item.id ? 'border-2 border-blue600' : ' border-2 border-transparent '}`} onClick={() => setSofaTexture(item.id)}>
                    <img src={item.image}  className='object-contain rounded-full'/>
                </div> )
                }
            </div>
        }
        {/* <button onClick={() => setChangeWall(changeWall ? false : true)} className={`p-4 m-1 text-white ${changeWall ? 'bg-red-400' : 'bg-green-400'}`}>تغییر تکستچر دیوار</button> */}
        {/* <button onClick={() => setChangeSofa(changeSofa ? false : true)} className={`p-4 m-1 text-white ${changeSofa ? 'bg-red-400' : 'bg-green-400'}`}> تغییر مبلمان</button> */}
        {/* <button onClick={() => setChangePosition(changePosition ? false : true)} className={`p-4 m-1 text-white ${changePosition ? 'bg-red-400' : 'bg-green-400'}`}> تغییر جای مبلمان</button> */}
        {
            choosedSection == 2 && 
            <div className='w-full'>
                <input className=' absolute opacity-0' value={''} autoFocus ref={hiddnInptRef} onKeyDown={onkeyDownControl} onKeyUp={onkeyUpControl} />
                <div className='w-full flex justify-center my-1'>
                    <p
                        onClick={() =>   setPosition([
                            position[0],
                            position[1] + positionControlValue,
                            position[2],
                        ])}
                    className={` cursor-pointer hover:bg-white/70 rounded-md h-12 w-10 flex items-center justify-center  m-1 ${keys?.w ? 'bg-red-300' : 'bg-white'}`}> 
                        <img src={arrowIcon} />
                    </p>
                    </div>
                    <div className='w-full flex justify-center my-1'>
                    <p 
                    onClick={() => setPosition([
                        position[0] + positionControlValue,
                        ...position?.filter((item, idx) => idx > 0)
                    ])}
                    className={`cursor-pointer hover:bg-white/70 h-10 rounded-md w-12 flex items-center justify-center ${keys?.d ? 'bg-red-300' : 'bg-white'} m-1`}> 
                    <img src={arrowIcon} className='rotate-90' />
                    </p>
                   <p className='w-10 m-1'>

                   </p>
                    <p
                        onClick={() => {
                setPosition([
                    position[0] - positionControlValue,
                    ...position?.filter((item, idx) => idx > 0)
                ])
                        }}                    
                    className={`cursor-pointer hover:bg-white/70 h-10 rounded-md w-12 flex items-center justify-center ${keys?.a ? 'bg-red-300' : 'bg-white'} m-1`}> 
                    <img src={arrowIcon} className='-rotate-90' />
                        
                    </p>
                    </div>
                    <div className='w-full flex justify-center my-1'>
                    <p
                    onClick={() => {
                        setPosition([
                            position[0],
                            position[1] - positionControlValue,
                            position[2],
                        ])
                    }}
                    className={`cursor-pointer hover:bg-white/70 h-12 rounded-md w-10 flex items-center justify-center ${keys?.s ? 'bg-red-300' : 'bg-white'} m-1`}> 
                    <img src={arrowIcon} className='rotate-180' />
                        
                    </p>
                    </div>
                </div>
        }
    </div>
  )
}

export default Controls